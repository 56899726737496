<template>
  <div class="content-wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ContentWrapper"
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  width: 100%;
  height: 1192px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>