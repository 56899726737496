<template>
  <div class="saint">
    <div class="image">
      <img :src="img" v-if="img" />
    </div>
    <div class="name">{{name}}</div>
  </div>
</template>

<script>
export default {
  name: "SaintCard",
  props: {
    img: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.saint {
  position: relative;
  width: 224px;
  display: flex;
  flex-direction: column;

  &::before {
    position: absolute;
    z-index: 1;
    top: 191px;
    left: 0px;
    content: "";
    width: 224px;
    height: 64px;
    -webkit-filter: blur(15px);
    filter: blur(15px);
    background: $color_shade;
  }

  .image {
    position: relative;
    z-index: 2;
    width: 224px;
    height: 224px;
    border-radius: 28px;
    background: $color_grey_3;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:active .image {
    transform: scale(0.9);
    transition-duration: 0.3s;
  }

  .name {
    position: relative;
    z-index: 2;
    margin-top: 20px;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -1px;
    color: rgba(#fff, 0.8);
  }
}
</style>