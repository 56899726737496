<template>
  <div class="tile">
    <div class="image">
      <img v-if="img" :src="require('@/assets/img/' + this.img)" />
      <span class="title">{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TileBig",
  props: {
    img: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.tile {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 356px;
    height: 96px;
    bottom: -64px;
    left: 0;
    -webkit-filter: blur(26px);
    filter: blur(26px);
    background-color: $color_shade;
  }
  .image {
    position: relative;
    z-index: 2;
    width: 356px;
    height: 526px;
    border-radius: 28px;
    overflow: hidden;
    background-color: $color_grey_3;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .title {
      position: absolute;
      bottom: 42px;
      left: 24px;
      width: 352px;
      font-size: 44px;
      font-weight: 500;
      line-height: 1.09;
      letter-spacing: -0.75px;
      color: rgba(#fff, 0.97);
    }
  }
  &:active .image {
    transform: scale(0.95);
    transition-duration: 0.3s;
  }
}
</style>