<template>
  <div class="events" v-if="events">
    <h1>Мероприятия</h1>
    <div class="tags">
      <swiper
        :options="{ slidesPerView: 'auto', touchRatio: 2 }"
      >
        <swiper-slide>
          <tag
            title="Все"
            :active="activeBadge === 0"
            @click.native="changeBadge(0)"
          />
        </swiper-slide>
        <swiper-slide
          v-for="(tag, index) in cities"
          :key="index"
        >
          <tag
            :title="tag.title"
            :active="tag.id === activeBadge"
            @click.native="changeBadge(tag.id)"
          />
        </swiper-slide>
      </swiper>
    </div>

    <div
      v-if="events && events.length"
      class="grid"
    >
      <event
        v-for="event in events"
        :key="event.id"
        :event="event"
        @click.native="goToEvent(event)"
      />
    </div>

    <div v-else class="events-plug">
      Ничего не найдено
    </div>
  </div>
</template>

<script>
import Tag from "@/components/Parts/Tag";
import Event from "@/components/Parts/EventCard";

export default {
  name: "Events",
  props: {
    limit: {
      type: Number,
      default: 0
    }
  },
  computed: {
    currentDate() {
      return this.$moment().format('YYYY-MM-DD');
    },
  },
  components: { Event, Tag },
  data() {
    return {
      badges: [],
      activeBadge: 0,
      cities: [],
      events: [],
      count: 10,
      page: 1,
    };
  },
  watch: {
    activeBadge(id) {
      if (id === 0) {
        this.getAllEvents();
      } else {
        api.getEventCity(id, this.currentDate).then(res => {
          this.events = res.data;
        });
      }
    }
  },
  mounted() {
    this.getAllEvents();
    this.scroll();

    api.getBadges().then(res => {
      this.badges = res.data;
    });

    api.getCity().then(res => {
      this.cities = res.data;
    });
  },
  methods: {
    goToEvent(event) {
      this.$router.push({ path: `/event/${event.id}` });
      this.$store.dispatch("setSelectedEvent", event);
    },

    changeBadge(id) {
      this.activeBadge = id;
      this.page = 1;
    },

    getAllEvents() {
      api.getAllEvents(this.currentDate, this.count).then(res => {
        this.events = res.data
      })
    },

    scroll() {
      const elementContainer = document.querySelector('.content-wrapper');

      elementContainer.addEventListener('scroll', () => {
        let scrollHeight = elementContainer.scrollHeight;
        if (elementContainer.scrollTop + elementContainer.clientHeight >= scrollHeight) {
          this.page = this.page + 1;
          this.$store.commit('setIsLoadingEvents', true);
          if (this.activeBadge === 0) {
            api.getAllEvents(this.currentDate, this.count, this.page).then(res => {
              this.events = [...this.events, ...res.data];
              this.$store.commit('setIsLoadingEvents', false);
            });
          } else {
            api.getEventCity(this.activeBadge, this.currentDate, this.count, this.page).then(res => {
              this.events = [...this.events, ...res.data];
              this.$store.commit('setIsLoadingEvents', false);
            });
          }
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.events {
  .grid {
    margin: 0 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .event {
      margin-bottom: 84px;
    }
  }

  .events-plug {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    font-size: 56px;
  }
}
</style>
